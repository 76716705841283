import * as React from "react";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { IMinuteFieldProps } from "../../Interfaces/props";

export default function CommuteBarMinuteField({ minuteValue, setMinuteValue }: IMinuteFieldProps) {
  function handleIncrement() {
    setMinuteValue(function (prev: number) {
      return Number(prev) + 1;
    });
  }

  function handleDecrement() {
    setMinuteValue(function (prev: number) {
      if (prev === 0) {
        return 0;
      } else {
        return Number(prev) - 1;
      }
    });
  }

  function handleFocus() {
    if (minuteValue === 0) {
      setMinuteValue("");
    }
  }

  function handleBlur() {
    if (minuteValue === "") {
      setMinuteValue(0);
    }
  }

  function handleUserInput(event: React.ChangeEvent<HTMLInputElement>) {
    const VALUE: string = event.target.value;
    let numberValue: number = Number(VALUE);
    if (isNaN(numberValue)) {
      setMinuteValue(0);
      return;
    }
    if (numberValue > 999) {
      setMinuteValue(0);
    } else {
      setMinuteValue(numberValue);
    }
  }
  return (
    <div className="flex items-center space-x-3 p-1 pb-2">
      <button
        aria-label="minus"
        className="text-blue-300 hover:text-yellow-400 transition-all duration-300 ease-in-out transform hover:scale-110"
        onClick={function () {
          handleDecrement();
        }}
      >
        <FaMinus size={35} />
      </button>
      <input
        type="text"
        value={minuteValue}
        onFocus={function () {
          handleFocus();
        }}
        onBlur={function () {
          handleBlur();
        }}
        onChange={function (event: React.ChangeEvent<HTMLInputElement>) {
          handleUserInput(event);
        }}
        className="pd-4 w-[50px] h-[40px] bg-blue-200 rounded-full text-center"
      />
      <button
        aria-label="plus"
        className="text-blue-300 hover:text-yellow-400 transition-all duration-300 ease-in-out transform hover:scale-110"
        onClick={function () {
          handleIncrement();
        }}
      >
        <FaPlus size={35} />
      </button>
    </div>
  );
}
