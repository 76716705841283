import * as React from "react";
import { useState, useEffect } from "react";
import { getEmployers } from "../../apiReceive/receiveEmployer";
import { IFilterDataProps } from "../../Interfaces/props";
import { t } from "i18next";
import { IFilterData } from "../../Interfaces/types";

function FilterEmployer({ filterData, setFilterData }: IFilterDataProps): React.ReactElement {
  const [EMPLOYERS, SET_EMPLOYERS]: [string[], React.Dispatch<React.SetStateAction<string[]>>] =
    useState<string[]>([]);
  const [SEARCH_TERM, SET_SEARCH_TERM]: [string, React.Dispatch<React.SetStateAction<string>>] =
    useState<string>("");

  async function fetchEmployers() {
    try {
      const EMPLOYERS_NAMES: string[] = await getEmployers();
      SET_EMPLOYERS(EMPLOYERS_NAMES);
    } catch {}
  }

  useEffect(function () {
    fetchEmployers();
  }, []);

  function dropEmployerFromList(list: string[], employer: string) {
    return list.filter(function (element: string) {
      return element !== employer;
    });
  }
  function updateSelectedItems(prevSelectedItems: string[], employerName: string): string[] {
    if (prevSelectedItems.includes(employerName)) {
      return dropEmployerFromList(prevSelectedItems, employerName);
    } else {
      return [...prevSelectedItems, employerName];
    }
  }

  function handleCheckboxChange(employerName: string): void {
    setFilterData(function (prev: IFilterData) {
      return {
        ...prev,
        userEmployernames: updateSelectedItems(prev.userEmployernames, employerName),
        userPage: 0,
      };
    });
  }

  function handleClear() {
    if (filterData.userEmployernames.length == 0) {
      return;
    }
    setFilterData(function (prev: IFilterData) {
      return {
        ...prev,
        userEmployernames: [],
        userPage: 0,
      };
    });
  }

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>): void {
    SET_SEARCH_TERM(e.target.value);
  }

  return (
    <div className="min-w-72 px-4 pb-4 bg-white rounded-xl border border-gray-300">
      <div className="flex justify-between items-center py-2">
        <h2 className="text-lg font-medium text-dunkelblau pl-2">{t("searchBar.employer")}</h2>
        <button
          className="border rounded-2xl bg-gray-50 flex px-2"
          onClick={handleClear}
          aria-label="clear button"
        >
          <p>Clear</p>
        </button>
      </div>
      <input
        className="w-full border rounded-md pl-3 py-1"
        value={SEARCH_TERM}
        placeholder={t("searchBar.search")}
        onChange={handleSearchChange}
      />
      <hr className="mt-2" />
      <div className="max-h-48 overflow-y-auto space-y-2 mt-2 px-3">
        {EMPLOYERS.map(function (employer: string) {
          return (
            employer.toLowerCase().includes(SEARCH_TERM.toLowerCase()) && (
              <div key={employer} className="flex items-center">
                <input
                  type="checkbox"
                  aria-label={`${employer} checkbox`}
                  checked={filterData.userEmployernames.includes(employer)}
                  onChange={function () {
                    handleCheckboxChange(employer);
                  }}
                  className="w-5 h-5 text-dunkelblau focus:ring-dunkelblau border-gray-300 rounded"
                />
                <span className="ml-2 text-gray-700">{employer}</span>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}

export default FilterEmployer;
