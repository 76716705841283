import * as React from "react";
import { IDialogProps } from "../Interfaces/props";
import { t } from "i18next";

function Description({ job }: IDialogProps) {
  return (
    <div className="max-w-screen-xl mx-auto bg-white">
      <div className="p-4 md:p-8">
        <h2 className="text-xl font-bold text-dunkelblau mb-4">{job.Title}</h2>
        <p className="text-gray-700">
          <a
            href={job.Website.JobURL}
            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
          >
            {t("description.origin")}
          </a>
        </p>
        <p className="text-gray-700">{job.Description}</p>
      </div>
      <div className="p-4 md:p-8 border-t border-gray-200">
        <h2 className="text-xl font-bold text-dunkelblau mb-4">{t("description.tasks")}</h2>
        <ul className="text-gray-700">
          {job.Tasks.map(function (task: string, index: number) {
            return (
              <li key={index} className="mb-2">
                <span className="inline-block mr-2 rounded-full bg-hellblau text-weiss px-2 py-1 text-xs font-semibold">
                  {index + 1}
                </span>
                {task}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default Description;
