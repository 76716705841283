import * as React from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Description from "./Description";
import Title from "./Title";
import Contact from "./Contact";
import { IFrameProps } from "../Interfaces/props";
import Disclaimer from "./Disclaimer";

function Frame({ isOpen, job, onClose }: IFrameProps) {
  return (
    <Dialog open={isOpen} onClose={onClose} className="fixed inset-0 z-50 overflow-y-auto">
      <div className="fixed inset-0 bg-schwarz bg-opacity-50"></div>

      <div className="fixed inset-0 flex items-center justify-center p-2 sm:p-4">
        <DialogPanel
          className="w-[95%] sm:w-[90%] md:w-[85%] lg:w-[80%] 
                    h-[98vh] sm:h-[95vh] md:h-[90vh] 
                    flex flex-col bg-weiss 
                    shadow-2xl rounded-lg overflow-hidden"
        >
          <div className="sticky top-0 bg-weiss pt-6 pb-4 px-4 sm:px-6 lg:px-8 z-20 border-b border-gray-100">
            <button
              onClick={onClose}
              aria-label="Close Dialog"
              className="absolute top-4 right-4 sm:top-6 sm:right-6 lg:top-8 lg:right-8 
                       focus:outline-none z-30"
            >
              <XMarkIcon
                className="w-6 h-6 sm:w-8 sm:h-8 text-dunkelblau hover:text-orange 
                                  transition-colors duration-200"
              />
            </button>
            {job && <Title job={job} />}
          </div>

          {job && (
            <div className="flex-1 overflow-auto">
              <Disclaimer />
              <div className="p-4 sm:p-6 lg:p-8 flex flex-col lg:flex-row gap-6 lg:gap-8">
                <div className="w-full lg:w-2/3">
                  <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
                    <Description job={job} />
                  </div>
                </div>

                <div className="w-full lg:w-1/3">
                  <div className="lg:sticky lg:top-6">
                    <Contact job={job} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </DialogPanel>
      </div>
    </Dialog>
  );
}

export default Frame;
