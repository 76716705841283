import * as React from "react";
import { IDialogProps } from "../Interfaces/props";
import type { ReactElement } from "react";
import { MapPinIcon, EnvelopeIcon, BuildingOfficeIcon, LinkIcon } from "@heroicons/react/20/solid";
import { t } from "i18next";

function Contact({ job }: IDialogProps): ReactElement {
  return (
    <div className="space-y-4">
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
        <div className="p-6 space-y-6">
          <h2 className="text-xl font-bold text-dunkelblau">{t("contact.info")}</h2>

          <div className="space-y-4">
            <div className="flex items-start gap-4">
              <BuildingOfficeIcon
                className="h-5 w-5 text-dunkelblau flex-shrink-0 mt-0.5"
                title={t("contact.employer")}
              />
              <div>
                <p className="font-medium text-dunkelblau">{job.Employer.ShortName}</p>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <EnvelopeIcon
                className="h-5 w-5 text-dunkelblau flex-shrink-0"
                title={t("contact.contact")}
              />
              <p className="text-dunkelblau">{job.Employer.Emails}</p>
            </div>

            <div className="flex items-center gap-4">
              <LinkIcon
                className="h-5 w-5 text-dunkelblau flex-shrink-0"
                title={t("contact.link")}
              />
              <a
                href={job.Website.JobURL}
                className="text-blue-600 hover:text-blue-800 transition-colors hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("contact.goToAd")}
              </a>
            </div>

            <div className="flex items-start gap-4">
              <MapPinIcon
                className="h-5 w-5 text-dunkelblau flex-shrink-0 mt-0.5"
                title={t("contact.location")}
              />
              <div className="text-dunkelblau">
                <p>
                  {job.Location.Street} {job.Location.HouseNumber}
                </p>
                <p>
                  {job.Location.PostalCode} {job.Location.City}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
