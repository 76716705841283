import React from "react";
import { fetchAutoCompleteAddress } from "../../apiReceive/receiveJob";
import { AxiosResponse } from "axios";
import { ICoordinateProps } from "../../Interfaces/props";
import { useTranslation } from "react-i18next";

export default function AddressField({ setUserLatitute, setUserLongitute }: ICoordinateProps) {
  const { t: T, ready: READY } = useTranslation();
  const [ADDRESS_PLACEHOLDER, SET_ADDRESS_PLACEHOLDER]: [
    string,
    React.Dispatch<React.SetStateAction<string>>,
  ] = React.useState<string>(READY ? T("commuteBarAddressField.address") : "");

  React.useEffect(
    function () {
      if (READY) {
        SET_ADDRESS_PLACEHOLDER(T("commuteBarAddressField.address"));
      }
    },
    [READY, T],
  );

  const [USER_INPUT, SET_USER_INPUT]: [string, React.Dispatch<React.SetStateAction<string>>] =
    React.useState<string>("");

  const [SUGGESTION, SET_SUGGESTION]: [
    AxiosResponse | null,
    React.Dispatch<React.SetStateAction<AxiosResponse | null>>,
  ] = React.useState<AxiosResponse | null>(null);

  async function handleUserInput() {
    if (!USER_INPUT) {
      SET_SUGGESTION(null);
      return;
    }

    try {
      SET_SUGGESTION(await fetchAutoCompleteAddress(USER_INPUT));
    } catch {
      SET_SUGGESTION(null);
    }
  }

  async function handleSuggestionClick(SUGGESTION: AxiosResponse) {
    setUserLatitute(getLatitute(SUGGESTION));
    setUserLongitute(getLongitute(SUGGESTION));
    SET_SUGGESTION(null);
    SET_USER_INPUT(parseAddress(SUGGESTION));
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    SET_USER_INPUT(e.target.value);
  }

  return (
    <div className="relative w-full">
      <input
        type="text"
        placeholder={ADDRESS_PLACEHOLDER}
        value={USER_INPUT}
        className="w-full border-gray-300 border rounded-full placeholder-gray-700 text-center bg-blue-50 p-2 transition-all duration-300 ease-in-out focus:bg-white focus:border-blue-500"
        onFocus={function () {
          SET_ADDRESS_PLACEHOLDER("");
        }}
        onBlur={function () {
          SET_ADDRESS_PLACEHOLDER(T("commuteBarAddressField.address"));
        }}
        onChange={handleChange}
        onKeyDown={function (e: React.KeyboardEvent<HTMLInputElement>) {
          if (e.key !== "Enter") {
            return;
          }
          handleUserInput();
        }}
      />
      {SUGGESTION &&
        SUGGESTION.data?.features[0] &&
        SUGGESTION.data.features[0].properties.street && (
          <ul className="absolute w-full bg-white border rounded-2xl hover:bg-blue-50">
            {
              <li
                onClick={function () {
                  handleSuggestionClick(SUGGESTION);
                }}
                className="border-b flex justify-center cursor-pointer last:border-b-0 p-1 ml-2 transition-all duration-300 ease-in-out"
              >
                {parseAddress(SUGGESTION)}
              </li>
            }
          </ul>
        )}
    </div>
  );
}

function parseAddress(SUGGESTION: AxiosResponse) {
  let street: string = SUGGESTION.data.features[0].properties.street;
  let housenumber: string = SUGGESTION.data.features[0].properties.housenumber;
  let locality: string = SUGGESTION.data.features[0].properties.locality;

  if (!street || !housenumber || !locality) {
    return "";
  }

  return `${street} ${housenumber}, ${locality}`;
}

function getLongitute(SUGGESTION: AxiosResponse) {
  return SUGGESTION.data.features[0].geometry.coordinates[0];
}

function getLatitute(SUGGESTION: AxiosResponse) {
  return SUGGESTION.data.features[0].geometry.coordinates[1];
}
