import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import JobList from "./components/JobListFrame";
import DatabaseToolFrame from "./components/DatabaseTool/DatabaseToolFrame";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<JobList />} />
          <Route path="/tool/:privilege/*" element={<DatabaseToolFrame />} />
          <Route path="/job/:jobId" element={<JobList />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
