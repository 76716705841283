import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";

const ROOT: ReactDOM.Root = ReactDOM.createRoot(document.getElementById("root"));
i18n
  .init({
    lng: "de",
    ns: ["translation"],
    defaultNS: "translation",
    fallbackLng: "de",
    supportedLngs: ["en", "de"],
    backend: {
      loadPath: "/Locales/{{lng}}/translation.json",
    },
    returnNull: false,
  })
  .then(function () {
    ROOT.render(
      <React.StrictMode>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </React.StrictMode>,
    );
  });
