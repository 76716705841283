import * as React from "react";
import { t } from "i18next";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { IFilterData } from "../Interfaces/types";

interface ISortControlProps {
  filterData: IFilterData;
  setFilterData: React.Dispatch<React.SetStateAction<IFilterData>>;
}

export default function SortControl({
  filterData,
  setFilterData,
}: ISortControlProps): React.ReactElement {
  function handleToggle() {
    setFilterData(function (prev: IFilterData) {
      return {
        ...prev,
        userSortOrder: prev.userSortOrder == "DESC" ? "ASC" : "DESC",
      };
    });
  }

  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setFilterData(function (prev: IFilterData) {
      return {
        ...prev,
        userSortMode: e.target.value,
      };
    });
  }

  return (
    <>
      <label className="block mb-2 font-medium text-dunkelblau">{t("searchBar.sort")}</label>
      <div className="flex gap-2">
        <select
          className="w-full p-2 border rounded-lg focus:outline-none focus:border-dunkelblau"
          onChange={handleChange}
        >
          <option value="ApplicationDeadline">{t("searchBar.deadline")}</option>
          <option value="Title">A-Z</option>
          <option value="created_at">{t("searchBar.createdDate")}</option>
          <option value="duration">{t("commuteBar.commuteTime")}</option>
        </select>
        <button
          onClick={handleToggle}
          className="px-4 py-2 bg-dunkelblau text-white rounded hover:bg-hellblau focus:outline-none flex items-center justify-center"
        >
          {filterData.userSortOrder === "ASC" ? (
            <ChevronUpIcon className="h-5 w-5" />
          ) : (
            <ChevronDownIcon className="h-5 w-5" />
          )}
        </button>
      </div>
    </>
  );
}
