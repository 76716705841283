import * as React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { t } from "i18next";

function Disclaimer(): React.ReactElement {
  return (
    <div className="w-full p-4 sm:p-6 lg:p-8">
      <div className="bg-orange/5 border border-orange/20 rounded-xl p-4">
        <div className="flex items-start gap-3">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-orange flex-shrink-0 mt-0.5"
            data-testid="exclamation-triangle-icon"
          />
          <div className="space-y-1">
            <h3 className="font-medium text-dunkelblau">{t("disclaimer.title")}</h3>
            <p className="text-sm text-gray-600">
              <span>{t("disclaimer.originalContent")}</span>{" "}
              <span>{t("disclaimer.liability")}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Disclaimer;
