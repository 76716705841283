import * as React from "react";
import { useState, useEffect, ReactElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Frame from "./Dialog";
import JobListItems from "./JobListItems";
import { IJob, IFilterData, IFilterResponse } from "../Interfaces/types";
import { LanguageIcon } from "@heroicons/react/20/solid";
import { t } from "i18next";
import i18n from "../i18n/i18n";
import { fetchJobs } from "../apiReceive/receiveJob";
import CommuteBar from "./CommuteBar";
import TitleSearch from "./TitleSearch";
import FilterButton from "./FilterElements/FilterButton";
import FilterControl from "./FilterControl";
import SortControl from "./SortControl";
import ActiveFilters from "./ActiveFilters";
import PageBar from "./PageBar";

function JobListFrame(): ReactElement {
  const NAVIGATE: ReturnType<typeof useNavigate> = useNavigate();
  const LOCATION: ReturnType<typeof useLocation> = useLocation();
  const [JOBS, SET_JOBS]: [IJob[], React.Dispatch<React.SetStateAction<IJob[]>>] = useState<IJob[]>(
    [],
  );
  const [SELECTED_JOB, SET_SELECTED_JOB]: [
    IJob | null,
    React.Dispatch<React.SetStateAction<IJob | null>>,
  ] = useState<IJob | null>(null);
  const [INTERNAL_ERROR, SET_INTERNAL_ERROR]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ] = useState<boolean>(false);
  const [LOADING, SET_LOADING]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(true);

  const [LANGUAGE, SET_LANGUAGE]: [string, React.Dispatch<React.SetStateAction<string>>] =
    useState<string>(i18n.language);

  const [FILTER_DATA, SET_FILTER_DATA]: [
    IFilterData,
    React.Dispatch<React.SetStateAction<IFilterData>>,
  ] = useState<IFilterData>({
    userSpecialty: "",
    userLanguage: "",
    userEmployernames: [],
    userDuration: "",
    userLongitute: "",
    userLatitute: "",
    userMode: 0,
    userSortOrder: "ASC",
    userSortMode: "ApplicationDeadline",
    userSearchterm: "",
    userPage: 0,
  });

  const [IS_FILTER_OPEN, SET_IS_FILTER_OPEN]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ] = useState<boolean>(false);

  const [COUNT, SET_COUNT]: [number, React.Dispatch<React.SetStateAction<number>>] = useState();

  useEffect(
    function (): void {
      fetchData();
    },
    [FILTER_DATA],
  );

  useEffect(
    function () {
      function handleLanguageChange(lng: string): void {
        SET_LANGUAGE(lng);
      }
      i18n.on("languageChanged", handleLanguageChange);

      return function () {
        i18n.off("languageChanged", handleLanguageChange);
      };
    },
    [LANGUAGE],
  );

  function changeLanguage(): void {
    const LNG: string = i18n.language;
    i18n.changeLanguage(LNG === "de" ? "en" : "de");
  }

  async function fetchData(): Promise<void> {
    try {
      SET_LOADING(true);
      let response: IFilterResponse = await fetchJobs(FILTER_DATA);
      let jobs: IJob[] = response.jobs;
      SET_JOBS(jobs);
      SET_COUNT(response.count);

      const JOBID: string = LOCATION.pathname.split("/job/")[1];

      if (JOBID && jobs.length > 0) {
        const JOB: IJob | undefined = jobs.find(function (j: IJob) {
          return j.JobID === Number(JOBID);
        });
        SET_SELECTED_JOB(JOB || null);
      } else {
        SET_SELECTED_JOB(null);
      }
    } catch {
      SET_INTERNAL_ERROR(true);
    } finally {
      SET_LOADING(false);
    }
  }

  function openDialog(job: IJob): void {
    SET_SELECTED_JOB(job);
    NAVIGATE(`/job/${job.JobID}`);
  }

  function closeDialog(): void {
    SET_SELECTED_JOB(null);
    NAVIGATE("/");
  }

  return (
    <div className="min-h-screen bg-gray-50 flex">
      <div className="block pl-[2%] pt-[15%]">
        <CommuteBar setFilterData={SET_FILTER_DATA} />
      </div>
      <div className="w-full max-w-screen-2xl pl-[5%] pr-[5%] font-sans p-8">
        <div className="flex justify-between relative mb-8">
          <h1 className="text-3xl font-bold text-dunkelblau">{t("jobListFrame.ads")}</h1>
          <p className="absolute right-10 text-gray-400 font-bold ">{t("jobListFrame.language")}</p>
          <LanguageIcon
            className="w-7 h-7 text-dunkelblau hover:text-gray-500 transition-transform duration-200"
            onClick={changeLanguage}
          />
        </div>

        <div className="w-full flex flex-wrap gap-4">
          <TitleSearch filterData={FILTER_DATA} setFilterData={SET_FILTER_DATA} />
          <FilterButton setIsFilterOpen={SET_IS_FILTER_OPEN} isOpen={IS_FILTER_OPEN} />
        </div>

        {IS_FILTER_OPEN && (
          <div className="w-full justify-items-center p-4 bg-white rounded-xl shadow-lg border-2 animate-fadeIn mt-4">
            <FilterControl filterData={FILTER_DATA} setFilterData={SET_FILTER_DATA} />
            <hr className="my-4 border-t border-gray-200" />
            <SortControl filterData={FILTER_DATA} setFilterData={SET_FILTER_DATA} />
          </div>
        )}

        <ActiveFilters filterData={FILTER_DATA} setFilterData={SET_FILTER_DATA} />

        <div className="mt-8 bg-white rounded-xl shadow-lg overflow-hidden">
          <div className="p-5 border-b flex justify-between items-center bg-gray-50">
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-600 font-medium">
                {COUNT} {t("jobListFrame.adsFound")}
              </span>
            </div>
          </div>

          <div>
            {!LOADING &&
              !INTERNAL_ERROR &&
              JOBS.map(function (job: IJob): ReactElement {
                return (
                  <JobListItems
                    key={job.JobID}
                    job={job}
                    onClick={function (): void {
                      openDialog(job);
                    }}
                  />
                );
              })}

            {LOADING && (
              <div className="p-12 text-center animate-pulse">
                <div className="inline-block p-4 rounded-full bg-hellblau/10">
                  <svg className="w-8 h-8 text-dunkelblau animate-spin" viewBox="0 0 24 24">
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                      fill="none"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    />
                  </svg>
                </div>
              </div>
            )}

            {!LOADING && !INTERNAL_ERROR && !COUNT && (
              <div className="p-8 text-center text-gray-500">
                <p>{t("jobListFrame.noJobsFound")}</p>
              </div>
            )}

            {!LOADING && INTERNAL_ERROR && (
              <div className="p-8 text-center text-gray-500">
                <p>{t("jobListFrame.serverError")}</p>
              </div>
            )}
          </div>
        </div>
        <PageBar filterData={FILTER_DATA} setFilterData={SET_FILTER_DATA} totalCount={COUNT} />
      </div>
      <Frame isOpen={!!SELECTED_JOB} job={SELECTED_JOB} onClose={closeDialog} />
    </div>
  );
}

export default JobListFrame;
