import * as React from "react";
import { useTranslation } from "react-i18next";

function Footer(): React.ReactElement {
  let { t } = useTranslation();

  return (
    <footer className="w-full bg-dunkelblau text-white py-4 mt-auto">
      <div className="container mx-auto text-center">
        <a
          href="https://www.leipzig-science-network.de/index.php/de-de/impressum"
          className="text-hellgelb hover:underline"
        >
          {t("footer.imprint")}
        </a>
      </div>
    </footer>
  );
}

export default Footer;
